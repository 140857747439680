
import { Vue, Component, Provide, Watch } from 'vue-property-decorator'
import CheckoutTripSplitNotice from '@/components/CheckoutTripSplitNotice.vue'
import CheckoutQuoteSummary from '@/components/CheckoutQuoteSummary.vue'
import CheckoutPaymentInformation from '@/components/CheckoutPaymentInformation.vue'
import ReservationCancellationPolicy from '@/components/ReservationCancellationPolicy.vue'
import CheckoutTerms from '@/components/CheckoutTerms.vue'
import CheckoutValuePropositions from '@/components/CheckoutValuePropositions.vue'
import CheckoutHeader from '@/components/CheckoutHeader.vue'
import CheckoutSubmitButton from '@/components/CheckoutSubmitButton.vue'
import CheckoutSummaryOverlay from '@/components/CheckoutSummaryOverlay.vue'
import CheckoutTripDetailsAndItinerary from '@/components/CheckoutTripDetailsAndItinerary.vue'
import quotes from '@/services/quotes'
import payment from '@/services/payment'
import {
  ComputedPaymentProfile,
  CheckoutDetailResult,
  SimplifiedPaymentGateway,
  CheckoutDetailQuote,
  CustomerAccount,
  RefundPolicyEntry,
  StagePaymentMethod,
} from '@/models/dto'
import { FormattedPaymentMethod } from '@/models/FormattedPaymentMethod'
import auth from '@/store/modules/auth'
import split from '@/store/modules/split'
import StickyColumnLayoutV2 from '@/layouts/StickyColumnLayoutV2.vue'
import { CompanyId, PaymentMethodTypeKey, PaymentGatewayTypeKey, SplitTrafficType } from '@/utils/enum'
import { currencyFilter } from '@/utils/string'
import { getEarliestDate } from '@/utils/datetime'
import customerAccount from '@/services/customerAccount'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'

@Component({
  components: {
    StickyColumnLayoutV2,
    CheckoutTripSplitNotice,
    CheckoutQuoteSummary,
    CheckoutPaymentInformation,
    ReservationCancellationPolicy,
    CheckoutTerms,
    CheckoutValuePropositions,
    CheckoutHeader,
    CheckoutSubmitButton,
    CheckoutSummaryOverlay,
    CheckoutTripDetailsAndItinerary,
  },
})
export default class GuestCheckout extends Vue {
  @Provide('isInGuestCheckout') isInGuestCheckout = true

  refreshInterval: any = null
  isSubmitting = false
  checkoutDetail: CheckoutDetailResult = null
  quote: CheckoutDetailQuote = null
  formattedPaymentMethodData: FormattedPaymentMethod = null
  paymentProfile: ComputedPaymentProfile = null
  paymentServiceError = ''
  showActionOverlay = true
  customerAccount: CustomerAccount = null
  effectiveDefaultValueSet: CustomerAccountDefaultValueSet = null

  get quoteId(): number {
    const quoteId = this.$route.params.quoteId
    return quoteId ? parseInt(quoteId) : null
  }

  get refundPolicyPercent(): number {
    return this.checkoutDetail?.refundPolicyPercent
  }

  get refundPolicy(): RefundPolicyEntry[] {
    return this.checkoutDetail?.refundPolicy
  }

  get refundPolicyPercentValidUntilTime(): string {
    return this.checkoutDetail?.refundPolicyPercentValidUntilTime
  }

  get isElite(): boolean {
    return !!this.quote?.isElite
  }

  get isSelfServe(): boolean {
    return !!this.quote?.isSelfServe
  }

  get allowedPaymentMethods(): string[] {
    const paymentMethods = this.quote?.trips?.[0]?.paymentMethods
    if (!paymentMethods) {
      return []
    }
    return paymentMethods
      .filter((method) => method.isAllowed)
      .map((method) => method.paymentMethodType?.key)
  }

  get selectedPaymentMethodType(): string {
    return this.formattedPaymentMethodData?.activeMethod
  }

  get storedPaymentSelected(): boolean {
    return (
      this.selectedPaymentMethodType === PaymentMethodTypeKey.CreditCard &&
      !!this.formattedPaymentMethodData?.customerPaymentProfileId
    )
  }

  get isSingleColumn(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  get isTripSplit(): boolean {
    return this.quote?.trips.some((trip) => trip.isSplit)
  }

  get earliestStartDate(): string {
    return getEarliestDate(this.quote.trips.map((trip) => trip.startDate))
  }

  get buttonText(): string {
    const baseText = 'Reserve Now'
    if (!this.isSingleColumn) {
      return baseText
    }

    return `${baseText} for ${currencyFilter(this.checkoutDetail.totalAmount)}`
  }

  get fullWidth(): boolean {
    return this.$vuetify.breakpoint.width < 480
  }

  get checkoutPaymentMethods(): StagePaymentMethod[] {
    return this.quote?.trips?.[0]?.stagePaymentMethods?.checkoutPaymentMethods
  }

  checkElementVisibility() {
    if (!this.isSingleColumn) {
      return
    }
    this.showActionOverlay = !this.isOverlayButtonAboveRegularButton()
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.checkElementVisibility)
    clearInterval(this.refreshInterval)
  }

  async mounted(): Promise<void> {
    window.addEventListener('scroll', this.checkElementVisibility)

    try {
      await this.load()
      this.refreshInterval = setInterval(async () => {
        if (!this.isSubmitting) {
          await this.load()
        }
      }, 15000)
      this.trackViewCheckout()
    } catch (err) {
      console.error(err)
    }
  }

  async load(): Promise<void> {
    await this.getCheckoutDetail()
    if (auth.user) {
      await Promise.all([
        this.getCustomerAccount(),
        this.getEffectiveDefaultValueSet(),
      ])
    }

    split.updateKey(this.quote?.customerId?.toString() || SplitTrafficType.Anonymous)
  }

  async getCheckoutDetail(): Promise<void> {
    const quoteHash = this.$route.params.hash || null

    try {
      let response
      if (quoteHash) {
        response = await quotes.guestCheckoutDetail(quoteHash)
      } else {
        this.$router.push({ name: 'quote-index' })
        return
      }

      const { data } = response
      if (!data || !data.quote.isActive) {
        this.$router.push({ name: 'quote-index' })
        return
      } else if (data.quote.isConverted) {
        this.$router.push({
          name: 'guest-checkout.confirmation',
        })
        return
      }

      this.checkoutDetail = data
      this.quote = this.checkoutDetail.quote
    } catch (err) {
      clearInterval(this.refreshInterval)
      this.$router.push({ name: 'quote-index' })
    }
  }

  async getCustomerAccount(): Promise<void> {
    const { data } = await customerAccount.byId(
      auth.customerAccount.customerAccountId
    )
    this.customerAccount = data
  }

  async getEffectiveDefaultValueSet(): Promise<void> {
    const { data } = await customerAccount.effectiveDefaultValueSet(
      auth.customerAccount.customerAccountId
    )
    this.effectiveDefaultValueSet = data
  }

  trackViewCheckout(): void {
    this.$ga4Event('view_checkout', {
      isElite: this.isElite,
      isSelfServe: this.quote?.isSelfServe,
    })
  }

  trackPaymentError(): void {
    this.$ga4Event('payment_error', {
      isElite: this.isElite,
      isSelfServe: this.quote?.isSelfServe,
    })
  }

  trackQuoteConverted(): void {
    this.$ga4Event('quote_converted', {
      isElite: this.isElite,
      isSelfServe: this.quote?.isSelfServe,
      value: this.checkoutDetail?.totalAmount,
    })

    split.trackGuestQuoteConverted(this.checkoutDetail?.totalAmount)
  }

  isFormValid(): boolean {
    const paymentForm = this.$refs['payment-information'] as any
    return paymentForm.validate()
  }

  async getDefaultPaymentGateway(): Promise<SimplifiedPaymentGateway> {
    const defaultPaymentGatewayResponse = await payment.getDefaultGateway(
      CompanyId.CharterUP
    )
    const defaultGateway = defaultPaymentGatewayResponse.data.paymentGateways[0]
    return {
      id: defaultGateway.companyPaymentGatewayId,
      key: defaultGateway.paymentGatewayTypeKey,
    }
  }

  async getFinixPaymentGateway(): Promise<SimplifiedPaymentGateway> {
    const defaultPaymentGatewayResponse = await payment.getPaymentGateways()
    const finixGateway = defaultPaymentGatewayResponse
      .data
      .paymentGateways
      .filter((paymentGateway) => paymentGateway.companyId === CompanyId.CharterUP)
      .filter((paymentGateway) => paymentGateway.checkoutPageId === null)
      .filter((paymentGateway) => paymentGateway.paymentGatewayTypeKey === PaymentGatewayTypeKey.Finix)
      ?.[0]
    return {
      id: finixGateway?.companyPaymentGatewayId,
      key: finixGateway?.paymentGatewayTypeKey,
    }
  }

  async submit(): Promise<void> {
    if (this.isSubmitting) {
      return
    }

    if (!this.storedPaymentSelected && !this.isFormValid()) {
      return
    }

    this.paymentServiceError = ''
    this.isSubmitting = true

    let tokenizedPaymentInfo
    let defaultPaymentGateway

    try {

      // If we're using a check, we don't need to tokenize payment info
      if (this.selectedPaymentMethodType === PaymentMethodTypeKey.Check) {
        tokenizedPaymentInfo = { tokens: [], paymentGateway: null }

      // If we have pre-saved card, we just add the default payment gateway
      // TODO: Remove this; we shouldn't need to send a payment gateway in the request (should use gateway saved to profile)
      } else if (this.storedPaymentSelected) {
        defaultPaymentGateway = await this.getDefaultPaymentGateway()

      // If we're using Finix tokenization, we've already tokenized with the form above and can just get the assocaited payment gateway
      } else if (this.selectedPaymentMethodType === PaymentMethodTypeKey.CreditCard) {
        const finixPaymentGateway = await this.getFinixPaymentGateway()
        if (!finixPaymentGateway || !finixPaymentGateway?.id) {
          this.isSubmitting = false
          return
        }

        try {
          const finixForm = (this.$refs['payment-information'] as any).$refs['credit-card-form'] as any
          const token = await finixForm.getTokenizedFinixInfo()
          tokenizedPaymentInfo = {
            tokens: [token],
            paymentGateway: finixPaymentGateway
          }
        } catch (error) {
          this.isSubmitting = false
          return
        }

      // Otherwise, assume we're using Auth Net and tokenize with Accept.js
      } else {
        console.warn("Unable to process payment method type")
        this.isSubmitting = false
        return

      }

      const paymentMethodPayload = { ...this.formattedPaymentMethodData }
      delete paymentMethodPayload.customerPaymentProfileId
      delete paymentMethodPayload.saveForFuturePayments

      let convertResponse

      if (this.storedPaymentSelected) {
        convertResponse = await quotes.convert(
          this.quote,
          defaultPaymentGateway,
          paymentMethodPayload,
          null,
          this.formattedPaymentMethodData?.customerPaymentProfileId,
          true
        )
      } else {
        convertResponse = await quotes.convert(
          this.quote,
          tokenizedPaymentInfo,
          paymentMethodPayload,
          null,
          null,
          !!this.formattedPaymentMethodData?.saveForFuturePayments
        )
      }

      this.handleSubmitSuccess(convertResponse)
    } catch (error) {
      this.handleSubmissionError(error)
    }
  }

  handleSubmitSuccess(convertResponse: any): void {
    this.trackQuoteConverted()
    this.$router.push({
      name: 'guest-checkout.confirmation',
    })
  }

  handleSubmissionError(error: any): void {
    this.isSubmitting = false
    if (error?.response?.data?.message.includes('E00008')) {
      this.paymentServiceError =
        'We understand you may be trying to place a deposit or make a payment, but are unable to do so. Nothing to worry about, please call 1-855-920-2287 we can confirm your bus now and collect the deposit once our systems are back online.'
    } else {
      this.paymentServiceError = this.$t('checkout.PAYMENT_ERROR')
    }
    this.trackPaymentError()
  }

  isOverlayButtonAboveRegularButton() {
    const checkoutOverlaySubmitButtonRef = this.$refs
      .checkoutOverlaySubmitButton as any
    const checkoutOverlaySubmitButton = (checkoutOverlaySubmitButtonRef.$el as any).getBoundingClientRect()
    const checkoutSubmitButtonRef = this.$refs.checkoutSubmitButton as any
    const checkoutSubmitButton = (checkoutSubmitButtonRef.$el as any).getBoundingClientRect()

    return checkoutOverlaySubmitButton.top >= checkoutSubmitButton.top
  }
}
